import {useParams } from 'react-router-dom';
import {useState,useEffect} from 'react';


function ResolutionPage() {
let { id } = useParams()
  return (
    <div>
      {id}
    </div>
  );  
}

export default ResolutionPage