import {useState,useEffect} from 'react';


import WhipSheet from "./WhipSheet";
function WhipSheets(){
  let [debates, setDebates] = useState(null)
  
  useEffect(() => {
    const loadDebates = async () => {
      let resp = await fetch(`/loadres`)
      resp = await resp.json() 
      await setDebates(JSON.parse(resp))
      console.log(debates)
    } 
    loadDebates();
    console.log(debates)
  }, []);

  if (debates){
    console.log(debates, debates[0])
    return(
      <body>
        <div className="content">
          <div className="box">
            <h2>Archive of Resolutions</h2>
            <hr className="staticrypt-hr"/>
            {debates.map((debate) => 
              <WhipSheet
               pdf = {"debate/" + debate.id} 
               title = {debate.resolved}
               date = {debate.date}
               />)
              }
          </div>
        </div>
      </body>
    )
  }
  else{return null}
}

export default WhipSheets