import {useState} from 'react'

function RSVP(){
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [going, setGoing] =  useState("")
  const [disabled, setDisabled] =  useState("false")
  const [buttonName, setButtonName] = useState("Submit") 

  return(
    <div className="content">
      <form class ='submitForm' onSubmit={onFormSubmit} >
        <fieldset disabled = {disabled === "true"}>
          <div className="staticrypt-instructions">
            <p className="staticrypt-title">S'il Vous Plaît</p>
          </div>
          <hr className="staticrypt-hr"/>
          <label>
            <input nameName = 'firstName' value = {firstName} onChange = {(e) => setFirstName(e.target.value)} placeholder='First Name'/>
          </label>
          <hr className="staticrypt-hr"/>
          <label>
            <input name = 'lastName' value = {lastName} onChange = {(e) => setLastName(e.target.value)} placeholder='Last Name'/>
          </label>
          <hr className="staticrypt-hr"/>
          <label>
            <input name = 'email' value = {email} onChange = {(e) => setEmail(e.target.value)} placeholder='Email'/>
          </label>
          <hr className="staticrypt-hr"/>
          <p>Will You Be Attending?</p>
          <div className='buttons'> 
            <label> Yes
              <input type = "radio"  value = 'Yes' name = "going" onChange = {(e) => setGoing(e.target.value)} />
            </label>
            <label> Maybe
              <input type = "radio" value = 'Maybe' name = "going" onChange = {(e) => setGoing(e.target.value)} />
            </label>
            <label> No
              <input type = "radio" value = 'No' name = "going" onChange = {(e) => setGoing(e.target.value)} />
            </label>
          </div>
          <hr className="staticrypt-hr"/>
          <input type="submit" className="staticrypt-decrypt-button" value= {buttonName}/>
        </fieldset>
      </form>
  </div>
  )

  async function onFormSubmit(e){
    e.preventDefault()
    setButtonName("Submitted")
    setDisabled("true")

    console.log("called")
    const data = JSON.stringify({
      first: firstName,
      last: lastName,
      address: email,
      attending: going
     })
    const postRequest = {method: "PUT",
      headers: {'Content-Type':'application/json'},
      body:data
    }
    fetch(`/RSVP`, postRequest)
  }
}
export default RSVP