  import {Link} from 'react-router-dom';
  function WhipSheet({pdf,title, date}){ 
    return(
      <div>
        {date}
        <p><Link to ={pdf}>Resolved, {title}</Link></p>
        <hr className="staticrypt-hr"/>
      </div>

    )
  }

  export default WhipSheet