import './App.css';
import Home from './Home.js';
import WhipSheets from './WhipSheets';
import RSVP from './RSVP';
import TopNav from './Components/TopNav';
import ResolutionPage from './ResolutionPage.js'
import {
  Routes,
  Route,
} from "react-router-dom";
import Password from './Password';
import PrivateRoutes from './PrivateRoutes'
import { AuthProvider } from './Context/auth.context';


function App() {
  let user = localStorage.getItem("user");
  return (
    <><TopNav />
    <AuthProvider userData={user}>
      <Routes>
        <Route element={<PrivateRoutes/>}>
          <Route path='/whip-sheets' element={<WhipSheets/>} />
        </Route>
        <Route element={<PrivateRoutes/>}>
          <Route path='/RSVP' element={<RSVP/>} />
        </Route>
        <Route element={<PrivateRoutes/>}>
          <Route path='/' element={<Home />} />
        </Route>
        <Route element={<PrivateRoutes/>}>
          <Route path='/debate/:id' element={<ResolutionPage/>} />
        </Route>
        <Route path="/password" element = {<Password/>} />
      </Routes>
    </AuthProvider></>
    
  );
}

export default App;
