import {Link} from 'react-router-dom';
function TopNav() {
  return(    
    <div className="topnav">
    <Link to ="">James Duane Society</Link>
    <Link to ="/whip-sheets">Whip Sheet</Link>
    <Link to ="/RSVP">RSVP</Link>
    </div>
  )
}

export default TopNav