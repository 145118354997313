import {  Outlet } from 'react-router-dom'
import { useAuth } from './Context/auth.context';
import Password from './Password';

export const PrivateRoutes = () => {
  let { user } = useAuth();
  let auth = {'token':!(!user || !user.token || user.token === "")}
  if(!auth.token || auth.token.user === "guest"){
    return(
    <Password/>
    )
  }
  else{
    return (
      <Outlet/>
    )
  }
}

export default PrivateRoutes
