function Home() {
    
    return(
      <body>
        <div className="content">
        <div className="box">
          <h2>The Society</h2>
          <p>The James Duane society is a parliamentary debating society based in New York City
            committed to exploring and advancing the Western tradition through debate and dialectic.
            We seek to provide a home for heterodox thought in the city.
          </p>
          <p>We debate bi-weekly and host a variety of other social events.</p>
        </div>
        <div className="box">
        <h2>Presiding Officers</h2>
        <p>Robbert Van Battenburg, The Chairman <br></br>
        Luke Seminara, The Vice Chairman<br></br>
        Jordan Coimbra, The Secretary<br></br>
        Finnegan McNinch, The Treasurer<br></br>
        Charles Beck, The Chief Whip</p>
      </div>
        <div className="box">
          <h2>Contact</h2>
          <p> Email: info [at] jamesduane.org <br></br>
            urbit: ~fogleg-lossev
          </p>
        </div>
      </div>
    </body>
  )
}

export default Home
