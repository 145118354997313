import {useState,useEffect} from 'react';
import AuthApi from './api/auth';
import { API_SERVER } from './Config/configuration';
import { useAuth } from './Context/auth.context';

function Password(){
  const [passwordAttempt, setPasswordAttempt] = useState('')
  const [passwordCorrect, setPasswordCorrect] = useState(false);
  const {user,setUser} = useAuth()
  
  function changed(e){
    setPasswordAttempt(e.target.value)
  }

  function onFormSubmit(e){
    e.preventDefault()
    console.log(passwordAttempt)
    login(passwordAttempt)
  }
  useEffect(() => {
    async function checkPassword(){
      var response = await fetch(`/password/${passwordAttempt}`)
      .then(response => response.json())
      console.log(response)
      setPasswordCorrect(response.correct === "True")
    }
    checkPassword()  
  }, [passwordAttempt]);     

  return(
      <div className = 'content'> 
          <form onSubmit={onFormSubmit} className ="staticrypt-form"> 
            <div className="staticrypt-instructions">
                <p className="staticrypt-title">Protected Page</p>
                <p></p>
            </div>
            <hr className="staticrypt-hr"/>
            <input value = {passwordAttempt} 
              onChange = {changed}
              placeholder="Passphrase"
              autoFocus
              className="staticrypt-password"/>
            <input type="submit" className="staticrypt-decrypt-button" value="Enter" disabled = {!passwordCorrect}/>
         </form>
      </div>
  )
  async function login(password){
    let response = await AuthApi.Login({
      password,
    });
    console.log("login called", response)
    return SetProfile(response);
  }; 
  
  function SetProfile(response){
    if(!response){
      return
    }
    let user = { ...response.data.user };
    console.log("user1", user)
    console.log(user.token)
    user.token = response.data.token;
    //setUser is imported from the useAuth React Context
    setUser(user);
    //also set the user in local storage
    localStorage.setItem("user", user);
    console.log("user2", user)
    return;
  }; 
}



export default Password